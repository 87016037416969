import { z } from 'zod'
import { recaptcha } from '../../formats.js'
import { inviteNanoid } from '../../brands.js'
import { invites } from '../../codegen/zod/rise/invites.js'
import { defaultErrorResponses, defaultResponse } from '../../response.js'
import type { Schema } from '../../types.js'

const invitationResponse = defaultResponse.extend({
  data: z.array(
    z.object({
      nanoid: z.string(),
      action: z.string().min(1),
      role: invites.shape.role.nullable(),
      inviter_name: z.string().min(1),
      company: z.object({
        name: z.string().min(1),
        avatar: z.string(),
      }),
    }),
  ),
})

const putInvitationBody = z.object({
  status: z.enum(['accepted', 'rejected']),
})

export const schema = {
  '/dashboard/invitations': {
    get: {
      tags: ['Dashboard'],
      security: [{ bearerAuth: [] }],
      summary: 'Get invitations',
      response: {
        200: invitationResponse,
        ...defaultErrorResponses,
      },
    },
  },
  '/dashboard/invitations/:action/:nanoid': {
    put: {
      tags: ['Dashboard'],
      security: [{ bearerAuth: [] }],
      headers: recaptcha,
      summary: 'Update invitations',
      params: z.object({
        action: z.string().min(1).trim(),
        nanoid: inviteNanoid,
      }),
      body: putInvitationBody,
      response: {
        200: defaultResponse.extend({ data: putInvitationBody }),
        ...defaultErrorResponses,
      },
    },
  },
} as const satisfies Schema
